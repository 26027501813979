import React, { Component, Suspense } from "react";
import {
  AboutCard,
  OverviewCard,
  WellnessCard,
  InitativeCard,
  RecognitionCard,
  DevicesCard,
  RecognitionCardV2,
  InitativeCardV2,
  WellnessCardV2,
  DevicesCardV2,
} from "./style";
import PropTypes from "prop-types";
import { ImageUrl } from "../../utils/constants";
import moment from "moment";
import { getLastSyncDetails } from "../../utils/methods";
import Waiting from "../Waiting";
const HoverButton = React.lazy(() => import("../../components/common/HoverButton"));
const DevicePopup = React.lazy(() => import('./deviceSyncPopup'));

class Overview extends Component {
  constructor() {
    super();
    this.state = {
      showDevicepoup:false
    };
  }

  showPopup = (status) => {
    this.setState({ showDevicepoup: status });
  };

  refreshButtonIcon = () => (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0001 11.9993C20.0001 9.42219 18.9558 6.97149 17.0759 5.15238C16.5537 4.64712 15.666 4.64712 15.1437 5.15238C14.5953 5.65765 14.5953 6.51663 15.1437 7.02207C16.5275 8.36114 17.2585 10.1045 17.2585 11.9994C17.2585 15.4355 14.6998 18.2904 11.3578 18.8968V17.3304C11.3578 16.7997 10.7311 16.5218 10.3135 16.8251L6.24021 19.8569C5.90073 20.1097 5.90073 20.5896 6.24021 20.8423L10.3133 23.8741C10.7311 24.1772 11.3576 23.8993 11.3576 23.3688L11.3578 21.6003C16.2403 20.9433 20.0001 16.9009 20.0001 11.9994L20.0001 11.9993Z"
        fill="#9C9C9C"
      />
      <path
        d="M2.74151 12C2.74151 8.56389 5.30022 5.7089 8.64231 5.10253V6.66894C8.64231 7.1996 9.26899 7.47752 9.6866 7.1742L13.7597 4.16757C14.0992 3.91484 14.0992 3.43496 13.7597 3.18222L9.68679 0.125195C9.26899 -0.177936 8.6425 0.0999933 8.6425 0.630458L8.64231 2.39899C3.7598 3.03063 0 7.09836 0 11.9998C0 14.577 1.04429 17.0277 2.92424 18.8468C3.18541 19.0995 3.55092 19.2257 3.89025 19.2257C4.22974 19.2257 4.59526 19.0993 4.85627 18.8468C5.40466 18.3415 5.40466 17.4825 4.85627 16.9771C3.4987 15.638 2.74161 13.8695 2.74161 11.9998L2.74151 12Z"
        fill="#9C9C9C"
      />
    </svg>
  );

  render() {
    const { userModal, setUserDetails, role, userWellnessInterest, userConnectedDevice, shoutOutData, userInitiativesData } = this.props;
    let TotalRecognitionPoints = 0;
    shoutOutData &&shoutOutData.shoutout_count && shoutOutData.shoutout_count.length&&
      shoutOutData.shoutout_count.map((item) => {
        TotalRecognitionPoints = TotalRecognitionPoints + item.count;
      });
    let completedInitaitives = 0;
    userInitiativesData && userInitiativesData.initiatives && userInitiativesData.initiatives.length&&
      userInitiativesData.initiatives.map((item) => {
        if (item.is_taken === 1) {
          completedInitaitives = completedInitaitives + 1;
        }
      });
    const RegistrationDate =
      setUserDetails && setUserDetails?.user?.created_at
        ? moment(setUserDetails?.user?.created_at).format("MM/DD/YYYY")
        : "NA";
    const {showDevicepoup}=this.state;    
    const deviceSynced = userConnectedDevice&&userConnectedDevice.connected_devices;
    const userId=setUserDetails&&setUserDetails?.user?.['uid'];
    return (
      <div>
        <OverviewCard style={{marginTop: "80px"}}>
          <div className="view-card">
            <div className="prof-details">
              <div className="name-in">Profile Details</div>
              <button onClick={() => userModal()} className="user-edit-btn">
                Edit Profile
              </button>
            </div>
            <hr className="horizontal-line" />
            <div className="emp-detail">
              <div className="fieldNames">
                <div className="label-name-emp">FullName: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails?.user?.fname}{" "}
                  {setUserDetails && setUserDetails?.user?.lname}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Department: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails?.user?.department !== null
                    ? setUserDetails?.user?.department
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Company: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails.user.company_name !== null
                    ? setUserDetails.user.company_name
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Location: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails?.user?.location !== null
                    ? setUserDetails?.user?.location
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Email: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails.user.mail !== null
                    ? setUserDetails.user.mail
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Country: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails.user.country !== null
                    ? setUserDetails.user.country
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Contact: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails.user.phone_number !== null
                    ? setUserDetails.user.phone_number
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Birthday: </div>
                <div className="color">
                  {setUserDetails && setUserDetails.user.birthday !== null
                    ? moment(setUserDetails.user.birthday).format("DD MMMM YYYY")
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Registration Date: </div>
                <div className="prof-data">
                  {setUserDetails && setUserDetails?.user?.created_at
                    ? RegistrationDate
                    : "NA"}
                </div>
              </div>
              <div className="fieldNames">
                <div className="label-name-emp">Work Anniversary: </div>
                <div className="green">
                  {setUserDetails &&
                  setUserDetails.user.work_anniversary_date !== null
                    ? moment(setUserDetails.user.work_anniversary_date).format("DD MMMM YYYY")
                    : "NA"}
                </div>
              </div>
            </div>
          </div>
        </OverviewCard>
        {userWellnessInterest &&
          userWellnessInterest.interest &&
          userWellnessInterest.interest.length > 0 && (
          <WellnessCard>
            <div className="wellness-interest">
              <div className="wellness-title">
                <span className="title-text">Wellness Interests</span>
                <span className="wellness-count">{`(Total : ${userWellnessInterest.interest.length})`}</span>
              </div>
              <hr className="horizontal-line" />
              <div className="interest-card-wrapper">
                {userWellnessInterest && userWellnessInterest.interest && userWellnessInterest.interest.length > 0 &&
                  userWellnessInterest.interest.map((data, index) => (
                    <WellnessCardV2
                      key={index}
                      marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}
                    >
                      <div className="icon-wrapper">
                        <div className="interest-icon">
                          <img src={`${ImageUrl}/${data.interest_icon}`} />
                        </div>
                      </div>
                      <div className="interest-name">
                        <span>{data.interest_name}</span>
                      </div>
                    </WellnessCardV2>
                  ))}
              </div>
            </div>
          </WellnessCard>
        )}
        {setUserDetails.user.bio && (
          <AboutCard>
            <div className="about-user-section">
              <div className="title-text">
                About {setUserDetails?.user?.fname}
              </div>
              <hr className="horizontal-line" />
              <div className="about-user-details">
                {setUserDetails.user.bio}
              </div>
            </div>
          </AboutCard>
        )}
        {userInitiativesData &&
          userInitiativesData.initiatives &&
          userInitiativesData.initiatives.length > 0 && (
          <InitativeCard justify>
            <div className="company-initative">
              <div className="heading">
                My {moment().format("YYYY")} Company Initiatives &nbsp;
                <div className="total">{`(Completed : ${completedInitaitives})`}</div>
              </div>
              <hr className="horizontal-line2" />
              <div className="fitness-wrapper">
                {userInitiativesData && userInitiativesData.initiatives && userInitiativesData.initiatives.length&&
                  userInitiativesData.initiatives.map((item, index) => (
                    <InitativeCardV2
                      key={index}
                      marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}
                      color={item.font_color}
                    >
                      <div className="rectangle-org">
                        <img src={`${ImageUrl}/${item.logo}`} />
                      </div>
                      <div className="syncing-title">
                        {item.name}
                        {item.is_taken ? (
                          <div className="points">
                            {item.date? `Completed on ${moment(item.date).format("MMM DD, YYYY")}`: "Completed"}
                          </div>
                        ) : (
                          <div className="points">+{item.points} pts</div>
                        )}
                      </div>
                      {item.is_taken ? (
                        <img
                          className="right-mark"
                          src="/public/images/Right.svg"
                        />
                      ) : (
                        <img
                          className="right-mark"
                          src="/public/images/Wrong.svg"
                        />
                      )}
                    </InitativeCardV2>
                  ))}
              </div>
            </div>
          </InitativeCard>)}
        {shoutOutData &&
          shoutOutData.shoutout_count &&
          shoutOutData.shoutout_count.length > 0 && (
          <RecognitionCard>
            <div className="recognize-received">
              <div className="heading2">
                My {moment().format("YYYY")} Recognition Received{" "}
                <div className="count">{`(Total : ${TotalRecognitionPoints})`}</div>
              </div>
              <hr className="horizontal-line2" />
              <div className="welcome-wrapper">
                {shoutOutData && shoutOutData.shoutout_count&& shoutOutData.shoutout_count.length&&
                  shoutOutData.shoutout_count.map((item, index) => (
                    <RecognitionCardV2
                      key={index}
                      marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}
                    >
                      <div className="img">
                        <img src={`${ImageUrl}/${item.icon}`} />
                      </div>
                      <div className="welldone-title">{item.name}</div>
                      <div className="point">{item.count}</div>
                    </RecognitionCardV2>
                  ))}
              </div>
            </div>
          </RecognitionCard>
        )}
        {userConnectedDevice &&
          userConnectedDevice.connected_devices &&
          userConnectedDevice.connected_devices.length > 0 && (
          <DevicesCard>
            <div className="connect-devices">
              <div className="wrapperHeading">
                <div className="headingNew">My Connected Devices</div>
                <div className="refreshbutton">
                  {role==="ADMIN"&&<HoverButton
                    title={"Refresh"}
                    svgPath={this.refreshButtonIcon()}
                    onClick={() => this.showPopup(true)}
                    height="24px"
                    width="20px"
                  />}
                </div>
              </div>
              <hr className="horizontal-line" />
              <div className="device-wrapper">
                {userConnectedDevice && userConnectedDevice.connected_devices &&userConnectedDevice.connected_devices.length&&
                  userConnectedDevice.connected_devices.map((item, index) => (
                    <DevicesCardV2
                      key={index}
                      marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}
                    >
                      <div className="img2">
                        <img src={`${ImageUrl}/${item.device_image}`} />
                      </div>
                      <div className="wrapper">
                        <div className="watch-title">{item.device_name}</div>
                        {item.last_sync && (
                          <div className="bits">
                            Last Sync {getLastSyncDetails(item.last_sync)}
                          </div>
                        )}
                      </div>
                    </DevicesCardV2>
                  ))}
              </div>
            </div>
          </DevicesCard>
        )}
        <Suspense fallback={<Waiting/>}>
          {showDevicepoup&&<DevicePopup showModal={showDevicepoup} closeModal={this.showPopup} deviceSynced={deviceSynced} userId={userId}/>}
        </Suspense>
      </div>
    );
  }
}
Overview.propTypes = {
  userModal: PropTypes.func,
  setUserDetails: PropTypes.array,
  role: PropTypes.string,
  userWellnessInterest: PropTypes.array,
  userConnectedDevice: PropTypes.array,
  shoutOutData: PropTypes.array,
  userInitiativesData: PropTypes.array
};

export default Overview;
